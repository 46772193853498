export default {
  button: {
    size: {
      larger: "px-10 py-4 rounded",
      large: "px-5 py-3 rounded",
      regular: "px-4 py-2 rounded text-sm",
      small: "px-3 py-1 rounded text-sm",
    },
    primary: {
      base: "text-white bg-blue-600 border border-transparent hover:text-indigo-600 hover:bg-white hover:border-blue-600",
      active: "focus:ring focus:ring-indigo-300",
      disabled: "opacity-50 cursor-not-allowed",
    },
    outline: {
      base: "text-indigo-600 border-indigo-500 hover:bg-indigo-600 hover:text-white hover:border-indigo-600 border dark:text-gray-400 focus:outline-none",
      active:
        "active:bg-transparent hover:border-indigo-500 focus:border-indigo-500 active:text-indigo-500 focus:ring focus:ring-indigo-300",
      disabled: "opacity-50 cursor-not-allowed border-blue-600",
    },
  },
  backdrop: {
    base: "fixed py-10 inset-0 z-40 flex items-end bg-black bg-opacity-50 sm:items-start sm:justify-center overflow-y-auto",
  },
};
