// Initialize Cloud Firestore through Firebase
import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
initializeApp({
  apiKey: "AIzaSyBWfkVMXoJswG_8SGTX-F8Qwbrj8Fp13P0",
  authDomain: "hyperclapper.firebaseapp.com",
  projectId: "hyperclapper",
});

// const db = getFirestore();

export {};
